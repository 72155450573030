import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import homeStyle from "../assets/jss/homeStyle.jsx";

import "../assets/css/book.scss";
import LinearProgress from '@material-ui/core/LinearProgress';
import EndImage from "../assets/imgs/ba-about.jpg"
import PoemBook from "../components/FlipBook";

class PoemPage extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
        poems: [],
        dataLoaded: false,
      };
    }
  componentDidMount () {
    fetch('/poems.json')
      .then(r => r.json())
      .then(json => {
        this.setState({poems: json,dataLoaded:true});
      });
  }
  render() {
    const v = this.state.dataLoaded ?
        <PoemBook
            poems={this.state.poems}
            endImage={EndImage}
            bookTitle ={"Bảo Nguyên Thi Tập"}/>
        : <LinearProgress /> ;
    return v;
  }
}
PoemPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(homeStyle)(PoemPage);
