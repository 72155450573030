// @material-ui/icons
import HomeIcon from '@material-ui/icons/Home';
//poems
//writing
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks' ;
//photos
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ContactMailIcon from '@material-ui/icons/ContactMail';

//writing for

import RateReviewIcon from '@material-ui/icons/RateReview';

import HomePage from './views/HomePage.jsx';
import CommentPage from './views/CommentPage.jsx';
import PhotoPage from './views/PhotoPage.jsx';
import PoemPage from './views/PoemPage.jsx';
import  ConnectPage from './views/ConnectPage.jsx';

const mainRoutes = [
  {
    path: "/home",
    name: "Tiểu Sử",
    icon: HomeIcon,
    component: HomePage,
    layout: "/main"
  },
  {
    path: "/poems",
    name: "Tập thơ Quê Hương",
    icon: LibraryBooksIcon,
    component: PoemPage,
    layout: "/main"
  },
  {
    path: "/photos",
    name: "Một số hình ảnh ...",
    icon: PhotoLibraryIcon,
    component: PhotoPage,
    layout: "/main"
  },
  {
    path: "/contribute",
    name: "Những dòng tưởng niệm",
    icon: RateReviewIcon,
    component: CommentPage,
    layout: "/main"
  },
  {
    path:"/connect",
    name:"Liên lạc - Kết nối",
    icon: ContactMailIcon,
    component: ConnectPage,
    layout:"/main"
  }

];

export default mainRoutes;
