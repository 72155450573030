import React from "react";

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import homeStyle from "../assets/jss/homeStyle.jsx";
import ImageGallery from "react-image-gallery";

const images = [];
for (var i = 1; i <= 144; i++) {
    images.push({
        original: "/images/dad_pics/" + i + ".jpeg",
        thumbnail: "/images/dad_pics/" + i + ".jpeg",
    });
}

class PhotoPage extends React.Component {

    render() {
        return <ImageGallery items={images} showPlayButton={true} showThumbnails={false} useBrowserFullscreen={true} showIndex={true} showNav={true}/>
    }
}

PhotoPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(homeStyle)(PhotoPage);
