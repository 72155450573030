import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import homeStyle from "../assets/jss/homeStyle.jsx";
class ConnectPage extends React.Component {
  render() {
    return (<div>
      <p><b>nguyenngocbat.org</b> là website phi lợi nhuận, được tạo ra tưởng nhớ <b>Ông Nguyễn Ngọc Bát</b> &nbsp;
        cũng như giới thiệu <b> <a href="/main/poems"> Tập thơ Quê hương </a> </b>của Ông đến cùng bạn đọc</p>

      <p>Mọi liên hệ xin gởi về <b>info@nguyenngocbat.org</b></p>
      <p>Cám ơn!</p>
      <div style={{height:"500px"}}/>
      <p className={"small-text"}>Một số hình ảnh được tải từ <a href="https://www.pexels.com/license/">pexels.com!</a></p>
    </div>)
  }
}
ConnectPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(homeStyle)(ConnectPage);
