import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import homeStyle from "../assets/jss/homeStyle.jsx";
import aboutImg from "../assets/imgs/ba-about.jpg";

class HomePage extends React.Component {
  state = {
    aboutText:""
  };
  render() {
    return (
      <div>
        <div  >
          <img src={aboutImg} alt="Bat Nguyen" width="350px" display="block" />
        </div>
        <div>
          <p dangerouslySetInnerHTML={{__html: this.state.aboutText}}></p>
        </div>
      </div>
    )
  };
  componentDidMount (){
    fetch('/about.txt')
        .then(r => r.text())
        .then(text => {
          this.setState({aboutText:text});
        });
  }
}
HomePage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(homeStyle)(HomePage);
