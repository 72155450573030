import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import homeStyle from "../assets/jss/homeStyle.jsx";
import LinearProgress from '@material-ui/core/LinearProgress';
import EndImage from "../assets/imgs/ba-writing.jpg"

import WritingBook from "../components/WritingBook";
class CommentPage extends React.Component {
  state = {
    writings: [],
    dataLoaded: false
  }
  scrollTo=(pNum) => {
    document.getElementById("writings-"+pNum).scrollIntoView();
  };
  render() {
    const v = this.state.dataLoaded ?
        (
            <div>
              <WritingBook poems={this.state.writings} endImage={EndImage}/>
              <div>
                <p className="small-text">Tập sách này dành cho những bài viết về <b>Ông Nguyễn Ngọc Bát</b>. Liên hệ với chúng tôi ở <b>info@nguyenngocbat.org </b></p>
              </div>
            </div>
        )
        : (<LinearProgress />) ;
    return v;
  }
  componentDidMount () {
    fetch('/other_writings.json')
        .then(r => r.json())
        .then(json => {
          this.setState({writings: json,dataLoaded:true});
        });
  }
}
CommentPage.propTypes = {
  classes: PropTypes.object.isRequired
};


export default withStyles(homeStyle)(CommentPage);
