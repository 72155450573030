import React from "react";
import HTMLFlipBook from "react-pageflip";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import homeStyle from "../assets/jss/homeStyle.jsx";
import Link from '@material-ui/core/Link';
import cover from "../assets/css/images/orchid.jpg"


const PageCover = React.forwardRef((props, ref) => {
    return (
      <div className="page page-cover page-cover-writing" ref={ref} data-density="hard" style={{backgroundImage:`url(${cover})`}}>
        <div className="page-content">
          {props.children}
        </div>

      </div>
    );
  });
  const PageEnd = React.forwardRef((props, ref) => {
      return (
        <div className="page page-cover page-cover-writing-end" ref={ref} data-density="hard">
          {props.children}
        </div>
      );
    });
  const Page = React.forwardRef((props, ref) => {
    const poem = props.children;
    const picUrl = poem.pictureUrl;
    return (
      <div className="page" ref={ref}>
        {picUrl ? (<div className="page-content">
              <img src={picUrl} alt="" style={{display:"block",marginLeft:"auto",marginRight:"auto",width:"80%",marginTop:"10%"}}/>
              <div className="page-footer">Tưởng Niệm - Trang {props.number}</div>
            </div>):
            (<div className="page-content">
                {poem.header? (<h4 className="page-header">{poem.header}</h4>):""}
                {poem.title ? (<h3 className="page-title">{poem.title}</h3>):""}
                {poem.byline? (<div className="by-line">{poem.byline}</div>):""}

                <div className="page-text">
                {
                   poem.p.map((line,index)=> {
                     return <p key={index}>{line}</p>
                   })
                 }
                 {poem.sign? (<div className="by-line">{poem.sign}</div>):""}
                 {poem.endSignature?(<div className="by-line">{poem.endSignature} </div>):""}
                 {poem.endPictureUrl? (<img alt = "" src={poem.endPictureUrl} style={{display:"block",marginLeft:"auto",marginRight:"auto",width:"70%",marginTop:"10%"}}/>):""}
                </div>

                <div className="page-footer">Những dòng tưởng niệm - Trang {props.number}</div>
        </div>)};
      </div>
    );
  });

  class WritingBook extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        page: 0,
        totalPage: 0
      };
    }

    nextButtonClick = () => {
      this.flipBook.getPageFlip().flipNext();
    };

    prevButtonClick = () => {
      this.flipBook.getPageFlip().flipPrev();
    };
    goToPage=(pNum) => {
      this.flipBook.getPageFlip().turnToPage(pNum);
      this.flipBook.el.scrollIntoView({
        behavior: "smooth",
        block:"center",
        inline:"center"
      });
    };
    onPage = (e) => {
      this.setState({
        page: e.data,
        flipped:true,
      });
    };

    componentDidMount() {
      const height = document.getElementById('flipbook').clientHeight;
      this.setState({
        totalPage: this.flipBook.getPageFlip().getPageCount(),
        cheight:height,
        poems:this.props.poems
      });
    };
    paginate =() => {
      var p_poems = [];
      var pCount = 1;
      this.props.poems.forEach((item, idex) => {
        var lines = [];
        for(var i = 0; i< item.p.length;i+=10) {
          var v = item.p.slice(i,i+10);
          lines.push(v);
        }
        for(var j = 0; j< lines.length; j++) {
          if(j === 0 && lines.length > 1 ) {
            if(p_poems.length > 0 && p_poems[p_poems.length - 1].endPictureUrl === null) {
              p_poems.push({
                p: [],
                pictureUrl: "/images/memory/" + pCount + ".jpg"
              });
              pCount += 1;
            }
            p_poems.push({
              title: item.title,
              byline: item.byline,
              p:lines[j],
              from : item.from
            })
          } else if (j === lines.length -1) {
            if(j > 0) {
              var endPictureUrl = null;
              p_poems.push({
                header: item.title,
                p: lines[j],
                sign: item.sign,
                endSignature: item.from,
                endPictureUrl: endPictureUrl,
                from : item.from
              });
            } else {
              p_poems.push({
                title: item.title,
                p: lines[j],
                sign: item.sign,
                byline: item.from,
                endSignature: item.from,
                endPictureUrl: endPictureUrl,
                from : item.from
              });

            }
          }else {
            p_poems.push({
              p:lines[j],
              header: item.title,
              from : item.from
            })
          }

          if(pCount > 4) {
            pCount = 1;
          }

        }
      });
      return p_poems;
    }
    render() {

      const w = window.innerWidth >= 500 ? 400 : 380;
      const h = 600;
      const poems = this.paginate();
      var indexCount = 0;
      return (
        <div id='flipbook'>
          {this.state.flipped ? "": (<div className="small-text" style={{textAlign:"right", paddingRight:"100px"}}>(Chạm vào sách để lật trang)</div>)}
          <HTMLFlipBook
            width={w}
            height={h}
            size="stretch"
            minWidth={315}
            maxWidth={600}
            minHeight={400}
            maxHeight={550}
            maxShadowOpacity={0.5}
            showCover={true}
            mobileScrollSupport={true}
            onFlip={this.onPage}
            onChangeOrientation={this.onChangeOrientation}
            onChangeState={this.onChangeState}
            className="flip-book html-book demo-book container-md-writing"
            ref={(el) => (this.flipBook = el)}
          >
            <PageCover><h2>Những dòng tưởng niệm</h2></PageCover>
            {
              poems.map((poem,index)=>{
                return <Page number={index +1} key={index}>{poem}</Page>
              })
            }
            <PageEnd>
              <div>
                <img key={1} src={this.props.endImage} className="center page-center" alt="Loving memory of Mr. Bat Nguyen"/>
                <p key={2} className="center"><b>Nguyễn Ngọc Bát</b></p>
                <p key={3} className="center">1945 - 2020</p>

              </div>
            </PageEnd>
          </HTMLFlipBook>
          <div key ="div">
            <h3>Mục lục</h3>
            <ul style={{dispay:"flex",listStyle:"none", justifyContent:"space-around"}}>
            {
              poems.map((poem,index)=>{

                if(poem.title) {
                  indexCount ++;
                  return (<li key={index +"_li"}><Link component="button" variant="body2" key={100+index}
                  onClick={()=>{this.goToPage(index+1);}} >
                    {indexCount}. {poem.title}  - <b>{poem.from}</b>
                  </Link></li>)
                } else {
                  return <p key={index} style={{display:'none'}}/>
                }

              })
            }
            </ul>
          </div>
        </div>
      );
    }
  }
  WritingBook.propTypes = {
    classes: PropTypes.object.isRequired
  };
  export default withStyles(homeStyle)(WritingBook);
